import { insight001 } from "./insights/insight-001";
import { insight002 } from "./insights/insight-002";
import { insight003 } from "./insights/insight-003";
import { insight004 } from "./insights/insight-004";
import { insight005 } from "./insights/insight-005";
import { insight006 } from "./insights/insight-006";
import { insight007 } from "./insights/insight-007";
import { insight008 } from "./insights/insight-008";
import { insight009 } from "./insights/insight-009";
import { insight010 } from "./insights/insight-010";
import { insight011 } from "./insights/insight-011";
import { insight012 } from "./insights/insight-012";
import { insight013 } from "./insights/insight-013";
import { insight014 } from "./insights/insight-014";
import { insight015 } from "./insights/insight-015";
import { insight016 } from "./insights/insight-016";
import { insight017 } from "./insights/insight-017";
import { insight018 } from "./insights/insight-018";
import { insight019 } from "./insights/insight-019";
import { insight020 } from "./insights/insight-020";
import { insight021 } from "./insights/insight-021";
import { insight022 } from "./insights/insight-022";
import { insight023 } from "./insights/insight-023";
import { insight024 } from "./insights/insight-024";

export const insights = [
  insight024,
  insight023,
  insight022,
  insight021,
  insight020,
  insight019,
  insight018,
  insight017,
  insight016,
  insight015,
  insight014,
  insight013,
  insight012,
  insight011,
  insight010,
  insight009,
  insight008,
  insight007,
  insight006,
  insight005,
  insight004,
  insight003,
  insight002,
  insight001,
];
