import experience from "./media/icons/experience.svg";
import industry from "./media/icons/industry.svg";
import bridge from "./media/icons/bridge1.svg";
import relationship from "./media/icons/relationship.svg";

import analysis from "./media/icons/services/developers/market-supply-demand-analysis.svg";
import ongoing from "./media/icons/services/developers/ongoing.svg";
import identification from "./media/icons/services/developers/supplier-identification.svg";
import optimisation from "./media/icons/services/developers/supply-chain-optimisation.svg";

import bidding from "./media/icons/services/suppliers/bidding-management.svg";
import partnerships from "./media/icons/services/suppliers/business-partnerships.svg";
import campaigns from "./media/icons/services/suppliers/marketing-campaigns.svg";
import entry from "./media/icons/services/suppliers/market-entry-strategy.svg";
import execution from "./media/icons/services/suppliers/project-execution-support.svg";
import opportunities from "./media/icons/services/suppliers/project-opportunities.svg";
import risk from "./media/icons/services/suppliers/project-risk-assessment.svg";
import stakeholder from "./media/icons/services/suppliers/stakeholder-engagement.svg";

import verification from "./media/icons/services/investors/independent-verification.svg";
import marketEntry from "./media/icons/services/investors/market-entry-support.svg";
import insights from "./media/icons/services/investors/market-insigts-and-intelligence.svg";
import projectDue from "./media/icons/services/investors/project-due-diligence.svg";
import monitoring from "./media/icons/services/investors/project-monitoring.svg";
import riskAssessment from "./media/icons/services/investors/risk-assessment.svg";
import techDue from "./media/icons/services/investors/technical-due-diligence.svg";

import claim from "./media/icons/services/insurers/claim-management-support.svg";
import lossControl from "./media/icons/services/insurers/loss-control-and-risk-mitigation.svg";
// import marketEntrySupport from "./media/icons/insurers/market-entry-support.svg";
// import insights from "./media/icons/insurers/market-insigts-and-intelligence.svg";
import riskAssessmentSupport from "./media/icons/services/insurers/risk-assessment-and-underwriting-support.svg";

export const weBridgeLogo = {
  white: require("./media/logo/WeBridge-logo-white.png"),
  red: require("./media/logo/WeBridge-logo-red.png"),
};

// export const windmillPhotosArray = loadWindmillPhotos();
export const windmillPhotosArray = [
  require("./media/windmill/photos/WTG9.jpg"),
  require("./media/windmill/photos/WTG8.jpg"),
  require("./media/windmill/photos/WTG7.jpg"),
  require("./media/windmill/photos/WTG1.jpg"),
  require("./media/windmill/photos/WTG2.jpg"), //
  require("./media/windmill/photos/WTG3.jpg"),
  require("./media/windmill/photos/WTG4.jpg"),
  require("./media/windmill/photos/WTG5.jpg"),
  require("./media/windmill/photos/WTG6.jpg"),
  require("./media/windmill/photos/WTG10.jpg"), //
  require("./media/windmill/photos/WTG11.jpg"),
  require("./media/windmill/photos/WTG12.jpg"),
  require("./media/windmill/photos/WTG13.jpg"),
  require("./media/windmill/photos/WTG14.jpg"),
  require("./media/windmill/photos/WTG15.jpg"), //
];

export const constructionPhotosArray = [
  require("./media/windmill/photos/construction1.jpg"),
  require("./media/windmill/photos/construction2.jpg"),
  require("./media/windmill/photos/construction3.jpg"),
  require("./media/windmill/photos/construction4.jpg"),
  require("./media/windmill/photos/construction5.jpg"),
  require("./media/windmill/photos/construction6.jpg"),
  require("./media/windmill/photos/construction7.jpg"),
  require("./media/windmill/photos/construction8.jpg"),
  require("./media/windmill/photos/construction9.jpg"),
];

export const forDevelopersImg = require("./media/windmill/photos/WTG-d1.png");
export const forSuppliersImg = require("./media/windmill/photos/suppliers.png");

export const windmillVideosArray = [
  require("./media/windmill/videos/WTG1.mp4"),
  require("./media/windmill/videos/WTG2.mp4"),
  require("./media/windmill/videos/platform.mp4"),
  require("./media/windmill/videos/WTG4.mp4"),
  require("./media/windmill/videos/WTG5.mp4"),
  require("./media/windmill/videos/sailing.mp4"),
];

export const londonPhotosArray = [
  require("./media/london/london1.jpg"),
  require("./media/london/phone-booth.jpg"),
];

export const backgroundArtArray = {
  windmillArt: require("./media/background/windmill-art.png"),
  windmillWaterArt: require("./media/background/windmill-water-art.png"),
  logoArt: require("./media/background/logoArt.png"),
  insights: require("./media/background/insights.jpg"),
  investors: require("./media/background/investors.jpg"),
  insurers: require("./media/background/insurers.jpg"),
};

export const iconArray = {
  certificate: require("./media/icons/icons8-certificate-red.png"),
  integrity: require("./media/icons/icons8-integrity-red.png"),
  collaboration: require("./media/icons/icons8-collaboration-red.png"),
  linkedin: require("./media/icons/webridge-linkedin.png"),
  wechat: require("./media/icons/webridge-wechat.png"),
  experience: experience,
  industry: industry,
  bridge: bridge,
  relationship: relationship,
};

export const other = {
  wechatQR: require("./media/other/wechat-qr.jpg"),
};

export const insight1Media = [
  require("./media/forInsights/insight1/1.png"),
  require("./media/forInsights/insight1/2.png"),
  require("./media/forInsights/insight1/3.png"),
  require("./media/forInsights/insight1/4.png"),
  require("./media/forInsights/insight1/5.png"),
];

export const insight2Media = [
  require("./media/forInsights/insight2/WTG.png"),
  require("./media/forInsights/insight2/goldwind.png"),
  require("./media/forInsights/insight2/mingyang.png"),
  require("./media/forInsights/insight2/cssc.jpg"),
];

export const insight3Media = [
  require("./media/forInsights/insight3/1.png"),
  require("./media/forInsights/insight3/2.png"),
];

export const insight4Media = [
  require("./media/forInsights/insight4/map.png"),
  require("./media/forInsights/insight4/graph.png"),
];
export const insight5Media = [
  require("./media/forInsights/insight5/offshore wind farm construction-1.jpg"),
  require("./media/forInsights/insight5/offshore wind farm construction-2.jpg"),
  require("./media/forInsights/insight5/offshore wind jacket.jpg"),
  require("./media/forInsights/insight5/offshore wind farm construction-3.jpg"),
];

export const insight6Media = [
  require("./media/forInsights/insight6/offshore wind farm.jpg"),
];
export const insight7Media = [require("./media/forInsights/insight7/tax.jpg")];
export const insight8Media = [
  require("./media/forInsights/insight8/windmill.jpg"),
];
export const insight9Media = [require("./media/forInsights/insight9/logo.png")];
export const insight10Media = [
  require("./media/forInsights/insight10/sany exhibition.jpg"),
  require("./media/forInsights/insight10/cssc exhibition.jpg"),
  require("./media/forInsights/insight10/windmill construction.jpg"),
  require("./media/forInsights/insight10/Aaron and Boskalis.jpg"),
];

export const insight11Media = [
  require("./media/forInsights/insight11/CFD_Nov23.jpg"),
  require("./media/forInsights/insight11/offshore wind.jpg"),
  require("./media/forInsights/insight11/WeBridge Quote - AR6.png"),
];
export const insight12Media = [
  require("./media/forInsights/insight12/cop28-1.jpeg"),
  require("./media/forInsights/insight12/cop28-2.jpeg"),
  require("./media/forInsights/insight12/cop28-3.jpeg"),
  require("./media/forInsights/insight12/cop28-4.jpeg"),
  require("./media/forInsights/insight12/cop28-5.jpeg"),
  require("./media/forInsights/insight12/cop28-6.jpeg"),
  require("./media/forInsights/insight12/cop28-7.jpeg"),
  require("./media/forInsights/insight12/cop28-8.jpeg"),
];

export const insight13Media = [
  require("./media/forInsights/insight13/offshore wind developers.jpeg"),
];
export const insight14Media = [
  require("./media/forInsights/insight14/document.jpeg"),
  require("./media/forInsights/insight14/table1.jpeg"),
  require("./media/forInsights/insight14/table2.jpeg"),
  require("./media/forInsights/insight14/table3.jpeg"),
  require("./media/forInsights/insight14/table4.jpeg"),
];

export const insight15Media = [
  require("./media/forInsights/insight15/regulation.jpeg"),
];
export const insight16Media = [
  require("./media/forInsights/insight16/FOWT-2024-Conference-Marseille.jpg"),
];
export const insight17Media = [
  require("./media/forInsights/insight17/Global-Offshore-Wind-event-floor.jpg"),
  require("./media/forInsights/insight17/Global-Offshore-Wind-event-poster.jpg"),
];
export const insight18Media = [
  require("./media/forInsights/insight18/Offshore-Wind-Farm.jpeg"),
];
export const insight19Media = [
  require("./media/forInsights/insight19/at_WindEnergy_Hamburg.jpeg"),
];
export const insight20Media = [
  require("./media/forInsights/insight20/at_floating_offshore_wind.jpeg"),
];
export const insight21Media = [
  require("./media/forInsights/insight21/uk-china-offshore-wind-partnership-reception.jpg"),
  require("./media/forInsights/insight21/speech.jpg"),
  require("./media/forInsights/insight21/speech2.jpg"),
  require("./media/forInsights/insight21/Aaron-communicating.jpg"),
  require("./media/forInsights/insight21/event-group-photo.jpg"),
];
export const insight22Media = [
  require("./media/forInsights/insight22/Adu-Dhabi-exhibition-conference.jpg"),
];
export const insight23Media = [
  require("./media/forInsights/insight23/trump.jpeg"),
  require("./media/forInsights/insight23/sailing-on-sea.jpeg"),
  require("./media/forInsights/insight23/offshore-wind-farm.jpeg"),
];
export const insight24Media = [
  require("./media/forInsights/insight24/shipment.jpeg"),
  require("./media/forInsights/insight24/offshore-wind-shipping.jpeg"),
  require("./media/forInsights/insight24/shipment2.jpeg"),
];

export const servicesForDevelopersIcons = {
  analysis,
  ongoing,
  identification,
  optimisation,
};

export const servicesForSuppliersIcons = {
  bidding,
  partnerships,
  campaigns,
  entry,
  execution,
  opportunities,
  risk,
  stakeholder,
};

export const servicesForInvestorsIcons = {
  verification,
  marketEntry,
  insights,
  projectDue,
  monitoring,
  riskAssessment,
  techDue,
};

export const servicesForInsurersIcons = {
  claim,
  lossControl,
  marketEntry,
  insights,
  riskAssessmentSupport,
};

function loadWindmillPhotos() {
  const windmillPhotosArray = [];
  let i = 1;
  while (true) {
    try {
      const imagePath = `./windmillPhotos/windmill${i}.jpeg`;
      const image = require(imagePath);
      windmillPhotosArray.push(image.default);
      i++;
    } catch (error) {
      break;
    }
  }
  return windmillPhotosArray;
}
